* {
  margin:0;
  padding:0;
  box-sizing:border-box;
}
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #EEE;
  margin: 0;
  height: 100%;
  overflow-y: hidden;
}
#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}
header {
  display:flex;

  padding:15px;
  background-color: #3c3c3c;
}
header h1{
  color:#EEE;
  font-size: 28px;
  font-weight: 700;
  text-transform:uppercase;
}
.faqs {
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  padding-left: 20px;
}
.mal{
  font-size: 20px;
  font-weight: 700;
}
h2{
  font-size: 20px;
  font-weight: 700;
  /* margin-left: 20px; */
  margin-top:10px;
}
p{
  margin-top: 10px;
  margin-left:30px ;
}
.pic{
  margin-top: 30px;
}
.bill
{
  margin-left: 20px;
}
.mill{
  text-align: center;
  padding-top: 10%;
} 
.flex-section {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}
.flex-col-scroll {
  flex-grow: 1;
  width: 100%;
  overflow: auto;
  min-height: 98%;
  max-height: 98%;
}
.flex-no-shrink {
  flex-shrink: 0;
}
/* .image{
  position: fixed;
  min-width: 100%;
  max-width: 100%;
  background-size: cover;
  background-size:cover;
  background-position: center;
  -webkit-filter: blur(6px);
  } */